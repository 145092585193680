"use client";

import {
  ClerkLoaded,
  ClerkLoading,
  OrganizationSwitcher,
  UserButton,
} from "@clerk/nextjs";
import { Settings2 } from "lucide-react";
import React, { useMemo } from "react";
import { Button } from "./ui/button";
import { usePathname, useRouter } from "next/navigation";
import { Skeleton } from "./ui/skeleton";

export default function NavBar({ admin }: { admin: boolean }) {
  const router = useRouter();
  const pathname = usePathname();

  const isSettingsPage = useMemo(() => {
    return pathname === "/settings";
  }, [pathname]);

  return (
    <>
      <ClerkLoading>
        <div className="flex items-center py-4 px-2 md:px-4">
          <Skeleton className="h-[calc(var(--navbar-height)-2rem)] w-full" />
        </div>
      </ClerkLoading>
      <ClerkLoaded>
        <div className="flex flex-row justify-start h-[70px] items-center px-2 md:px-4">
          <div className="h-[32px]">
            <OrganizationSwitcher
              hidePersonal
              appearance={{
                elements: {
                  organizationPreviewMainIdentifier: {
                    fontSize: "12px",
                  },
                },
              }}
            />
          </div>
          {!isSettingsPage && admin && (
            <Button
              variant={"ghost"}
              className="ml-0 md:ml-4"
              onClick={() => {
                router.push("/settings");
              }}
            >
              <Settings2 className="w-4 h-4" />
            </Button>
          )}
          <div className="ml-auto">
            <UserButton />
          </div>
        </div>
      </ClerkLoaded>
    </>
  );
}
